import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Modal from 'react-modal';
import './css_file/coupon_management.css';
import { formatTime, couponTargetGroup, getTimeStampinSec } from '../utils/utils';
import { toast } from 'react-toastify';

function CouponManagement({ token }) {
    // data from backend
    const [data, setData] = useState([]);
    // condition for onClick types button
    const [condition, setCondition] = useState(-1);
    // mutiple select Rows
    const [selectedRows, setSelectedRows] = useState([]);
    // add coupon button 
    const [isAddCouponModalOpen, setIsAddCouponModalOpen] = useState(false);
    // save data for adding new coupon
    const [formData, setFormData] = useState({
        couponCode: '',
        fullAmount: '',
        discountAmount: '',
        couponName: '',
        singleUseCount: '',
        amountLimit: '',
        isActivated: '0',
        target: '0',
        startTime: '',
        endTime: ''
    });

    // columns titles
    const columnTitles = [
        '優惠碼',
        '滿額金額',
        '優惠金額',
        '是否啟用',
        '單人使用次數',
        '總數量',
        '已使用次數',
        '優惠對象',
        '開始時間',
        '到期時間',
        ' ',
    ];
    // coupon type
    const couponTypes = ['全部優惠券', '已啟用', '已禁用', '已失效',];
    // because backend engineer sucks, so need the mapping to rearrange
    // to my surprise his code is really fucking shit. 
    const couponTypesMapping = {
        0: '已啟用',
        1: '已禁用',
        2: '已失效',
    };
    const couponTypesButtonMapping = {
        0: -1,
        1: 0,
        2: 1,
        3: 2,
    };

    // useEffect hook for fetching data
    useEffect(() => {
        fetchData();
    }, [condition]);
    // fetch coupon data
    const fetchData = async () => {
        try {
            const response = await axios.get('https://api.thala.io/admin/CouponsType', {
                headers: {
                    'Authorization': token,
                },
                params: {
                    condition: condition,
                }
            });
            if (response.data.result != null) {
                setData(response.data.result);
            }
            else {
                setData([]);
            }

        } catch (error) {
            console.error('Error fetching coupon table data:', error);
        }
    };
    // post to backend for adding new coupon
    async function postNewCoupon() {
        try {
            const response = await axios.post(
                'https://api.thala.io/admin/createCouponType',
                {
                    DiscountType: 0,
                    Discount: parseInt(formData.discountAmount, 10),
                    LowerBond: parseInt(formData.fullAmount, 10),
                    Code: formData.couponCode,
                    Name: formData.couponName,
                    AmountLimit: parseInt(formData.amountLimit, 10),
                    PersonalLimit: parseInt(formData.singleUseCount, 10),
                    TargetGroup: parseInt(formData.target, 10),
                    Status: parseInt(formData.isActivated, 10),
                    StartAt: parseInt(getTimeStampinSec(formData.startTime), 10),
                    ExpireAt: parseInt(getTimeStampinSec(formData.endTime), 10),
                },
                {
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': token,
                    },
                }
            );

            return response.data;
        } catch (error) {
            console.error('Failed to add new coupon status:', error);
            throw error;
        }
    }

    //setting coupon status
    async function setCouponStatus(couponId, newStatus) {
        try {
            const response = await axios.post(
                'https://api.thala.io/admin/SetCoupontype',
                {
                    Id: couponId,
                    Condition: newStatus,
                },
                {
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': token,
                    },
                }
            );

            return response.data;
        } catch (error) {
            console.error('Failed to set coupon status:', error);
            throw error;
        }
    }

    async function deletedCoupon(couponId) {
        try {
            const response = await axios.post(
                'https://api.thala.io/admin/DeleteCoupontype',
                {
                    Id: couponId,
                },
                {
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': token,
                    },
                }
            );

            return response.data;
        } catch (error) {
            console.error('Failed to delete coupon:', error);
            throw error;
        }
    }

    // for multiply select rows
    const handleSelectAll = (e) => {
        if (e.target.checked) {
            setSelectedRows(data.map((_, index) => index));
        } else {
            setSelectedRows([]);
        }
    };

    const handleRowSelection = (index, e) => {
        if (e.target.checked) {
            setSelectedRows((prevSelectedRows) => [...prevSelectedRows, index]);
        } else {
            setSelectedRows((prevSelectedRows) => prevSelectedRows.filter((row) => row !== index));
        }
    };
    // handle add coupon button
    const handleAddCoupons = () => {
        setIsAddCouponModalOpen(true);
    }

    const handleConfirmAddCoupons = async (e) => {
        e.preventDefault();
        await postNewCoupon(formData);
        setIsAddCouponModalOpen(false);
    }

    const handleInputChange = (e) => {
        setFormData({
            ...formData,
            [e.target.name]: e.target.value
        });
    };

    const handleSettingCouponStatus = async (couponId, status) => {
        //  0 is activated, 1 is disabled, 2 is deleted
        try {
            if (status === 2) {
                await deletedCoupon(couponId);
            }
            else {
                await setCouponStatus(couponId, status);
            }
        }
        catch (err) {
            toast.error(err);
        }
    };

    return (
        <div>
            <div>
                {couponTypes.map((type, index) => (
                    <button
                        key={index}
                        onClick={() => setCondition(couponTypesButtonMapping[index])}
                        className="status-button"
                    >
                        {type}
                    </button>
                ))}
            </div>
            <div>
                <button
                    key="add-coupon-button"
                    onClick={() => handleAddCoupons()}
                    className="add-coupon-button"
                >
                    新增優惠券
                </button>
            </div>
            <div>
                <table className="coupon-management-table">
                    <thead>
                        <tr>
                            <th className="column-checkbox"><input type="checkbox" onChange={handleSelectAll} /></th>
                            <th className="column-no">No.</th>
                            {Array(11).fill(null).map((_, index) => <th className={`column${index}`} key={index}>{columnTitles[index]}</th>)}
                        </tr>
                    </thead>
                    <tbody>
                        {data.map((row, rowIndex) => (
                            <tr key={rowIndex}>
                                <td>
                                    <input
                                        type="checkbox"
                                        checked={selectedRows.includes(rowIndex)}
                                        onChange={(e) => handleRowSelection(rowIndex, e)}
                                    />
                                </td>
                                <td>{rowIndex + 1}</td>
                                <td>{row.Code}</td>
                                <td>{"$" + row.LowerBond}</td>
                                <td>{"$" + row.Discount}</td>
                                <td>{couponTypesMapping[row.Status]}</td>
                                <td>{row.PersonalLimit}</td>
                                <td>{row.AmountLimit}</td>
                                <td>{row.Amount}</td>
                                <td>{couponTargetGroup(row.TargetGroup)}</td>
                                <td>{formatTime(row.StartAt)}</td>
                                <td>{formatTime(row.ExpireAt)}</td>
                                <td >
                                    {row.Status === 0 ? < div className="couponStatus-button-container">
                                        <button onClick={() => handleSettingCouponStatus(row.ID, 2)} className="coupon-delete-button">刪除</button>
                                        <button onClick={() => handleSettingCouponStatus(row.ID, 1)} className="coupon-disabled-button">禁用</button>
                                    </div> : row.Status === 1 ? < div className="couponStatus-button-container">
                                        <button onClick={() => handleSettingCouponStatus(row.ID, 2)} className="coupon-delete-button">刪除</button>
                                        <button onClick={() => handleSettingCouponStatus(row.ID, 0)} className="coupon-activate-button">啟用</button>
                                    </div> : < div className="couponStatus-button-container">
                                        <button onClick={() => handleSettingCouponStatus(row.ID, 2)} className="coupon-delete-button">刪除</button>
                                    </div>}
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
                <Modal isOpen={isAddCouponModalOpen} onRequestClose={() => setIsAddCouponModalOpen(false)} contentLabel="descrition modal"
                    className="add-coupon-modal-content">
                    <form onSubmit={handleConfirmAddCoupons}>
                        <h2>新增優惠券</h2>
                        <div>
                            <label
                            >
                                <span className="input-title">優惠碼:</span>
                                <input type="text" placeholder="" name="couponCode" value={formData.couponCode} onChange={handleInputChange} />
                            </label>
                            <label>
                                <span className="input-title">滿額金額:</span>
                                <input type="text" placeholder="" name="fullAmount" value={formData.fullAmount} onChange={handleInputChange} />
                            </label>
                            <label>
                                <span className="input-title">優惠金額:</span>
                                <input type="text" placeholder="" name="discountAmount" value={formData.discountAmount} onChange={handleInputChange} />
                            </label>
                            <label>
                                <span className="input-title">優惠券名稱:</span>
                                <input type="text" placeholder="" name="couponName" value={formData.couponName} onChange={handleInputChange} />
                            </label>
                            <label>
                                <span className="input-title">單人使用次數:</span>
                                <input type="text" placeholder="" name="singleUseCount" value={formData.singleUseCount} onChange={handleInputChange} />
                            </label>
                            <label>
                                <span className="input-title">最大發行量:</span>
                                <input type="text" placeholder="" name="amountLimit" value={formData.amountLimit} onChange={handleInputChange} />
                            </label>

                            {/* Dropdowns */}
                            <label
                                className="dropdown-label">
                                <span className="input-title">是否啟用:</span>

                                <select name="isActivated" value={formData.isActivated} onChange={handleInputChange}>
                                    <option value="0">是</option>
                                    <option value="1">否</option>
                                </select>
                            </label>
                            <label
                                className="dropdown-label">
                                <span className="input-title">優惠對象:</span>
                                <select name="target" value={formData.target} onChange={handleInputChange}>
                                    <option value="0">全部對象</option>
                                    {/* <option value="option2">Option 2</option> */}
                                </select>
                            </label>

                            {/* DateTime Pickers */}
                            <label>
                                <span className="input-title">開始時間:</span>
                                <input type="datetime-local" name="startTime" value={formData.startTime} onChange={handleInputChange} />
                            </label>
                            <label>
                                <span className="input-title">到期時間:</span>
                                <input type="datetime-local" name="endTime" value={formData.endTime} onChange={handleInputChange} />
                            </label>
                            <div className="add-coupon-modal-button-container">
                                <button onClick={() => setIsAddCouponModalOpen(false)} className="add-coupon-modal-cancel-button">
                                    Cancel
                                </button>
                                <button type="submit" className="add-coupon-modal-confirm-button">
                                    Confirm
                                </button>
                            </div>
                        </div>
                    </form>
                </Modal>
            </div>

        </div >
    );
}

export default CouponManagement;
