import React, { useState } from 'react';
import { BrowserRouter as Router, Route, Routes, useNavigate } from 'react-router-dom';
import axios from 'axios';
import LandingPage from './LandingPage';
import './App.css';

function LoginPage() {
  const [account, setAccount] = useState('');
  const [password, setPassword] = useState('');
  const navigate = useNavigate();

  const handleLogin = async () => {
    try {
      // Perform your login logic here
      const response = await axios.post('https://api.thala.io/admin/login', {
        Username: account,
        Password: password,
      });

      console.log(response.data);

      // Assuming the response contains an "access_token" property when login is successful
      if (response.data.token) {
        // Navigate to the landing page upon successful login
        sessionStorage.setItem('token', response.data.token);
        sessionStorage.setItem('username', response.data.result.UserName);
        // const name = sessionStorage.getItem('userName');
        // console.log("name: " + name);
        navigate('/landing');
      } else {
        alert('Login failed. Please try again.');
      }
    } catch (error) {
      console.error('Error during login:', error);
      alert('Login failed. Please try again.');
    }

  };

  return (
    <div className="App">
      <header className="App-header">
        <h1>Thala</h1>
        <div>
          <label htmlFor="account">Account:</label>
          <input
            type="text"
            id="account"
            value={account}
            onChange={(e) => setAccount(e.target.value)}
          />
        </div>
        <div>
          <label htmlFor="password">Password:</label>
          <input
            type="password"
            id="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
        </div>
        <button classname="login-button" onClick={handleLogin}>Login</button>
      </header>
    </div>
  );
}

function App() {
  return (
    <Router>
      <Routes>
        <Route exact path="/" element={<LoginPage />} />
        <Route path="/landing" element={<LandingPage />} />
      </Routes>
    </Router>
  );
}

export default App;
